module.exports = {
  pathPrefix: '',
  siteUrl: 'https://sachu.me',
  siteTitle: 'Sachu Shaji Abraham',
  siteDescription: 'Sachu portofolio website',
  author: 'Sachu Shaji Abraham',
  postsForArchivePage: 3,
  defaultLanguage: 'en',
  disqusScript: process.env.DISQUS_SCRIPT || 'https://sachu-me.disqus.com/embed.js',
  pages: {
    home: '/',
    blog: 'blog',
    contact: 'contact',
    resume: 'resume',
    tag: 'tags',
  },
  social: {
    github: 'https://github.com/sachushaji',
    twitter: 'https://twitter.com/sachu_abraham',
    linkedin: 'https://www.linkedin.com/in/sachushaji/',
    rss: '/rss.xml',
  },
  contactFormUrl: process.env.CONTACT_FORM_ENDPOINT || 'https://getform.io/f/fd2f0a3c-5c89-4327-b198-f16dbf71b6f6',
  googleAnalyticTrackingId: process.env.GA_TRACKING_ID || '',
  tags: {
    blockchain: {
      name: 'blockchain',
      description: 'Blockchain technology helps us to secure provenance of a data without the need of a central entity',
      color: '#257acc',
    },
  },
};
